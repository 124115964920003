<template>
  <div class="account">
    <div class="department-name">小程序</div>
    <div class="form-info" v-loading="weChatState.loadingState">
      <el-form ref="formRef" :model="weChatState.data" :rules="rules" label-width="140px" label-position="right">
        <el-form-item label="所属公司" prop="companyName" :inline-message="true">
          <el-input v-model="weChatState.data.companyName" disabled :style="inputStyle"></el-input>
        </el-form-item>
        <el-form-item label="应用Id" prop="appId" :inline-message="true">
          <el-input
            v-model="weChatState.data.appId"
            maxlength="36"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入应用Id"
          ></el-input>
        </el-form-item>
        <el-form-item label="应用名称" prop="name" :inline-message="true">
          <el-input
            v-model="weChatState.data.name"
            maxlength="36"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入应用名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="应用密钥" prop="appSecret" :inline-message="true">
          <el-input
            v-model="weChatState.data.appSecret"
            maxlength="64"
            show-word-limit
            :style="inputStyle"
            placeholder="请输入应用密钥"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="button-info">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import { saveOrUpdate } from '@/apis/sys'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { getWeChatSettingInfo } from '@/hooks/sys'
export default defineComponent({
  name: 'applet',
  setup() {
    const store = useStore()

    const inputStyle = {
      width: '350px',
      'margin-left': '40px'
    }
    const userInfo = computed(() => {
      return store.state.userInfo
    })
    const { state: weChatState, initPageList } = getWeChatSettingInfo({
      companyNo: userInfo.value.companyNo,
      type: '2'
    })
    initPageList()
    const rules = {
      companyName: { required: true, message: '请输入所属公司', trigger: 'blur' },
      appId: { required: true, message: '请输入应用Id', trigger: 'blur' },
      name: { required: true, message: '请输入应用名称', trigger: 'blur' },
      appSecret: { required: true, message: '请输入应用密钥', trigger: 'blur' }
    }
    const formRef = ref(null)
    const onSubmit = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          let res = await saveOrUpdate(weChatState.data)
          if (res.code == 200) {
            ElMessage.success('操作成功')
          }
        }
      })
    }
    return {
      inputStyle,
      rules,
      formRef,
      onSubmit,
      weChatState
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
